import React, { useEffect, useState } from 'react';
import {IReservation,defaultReservation, IPRP,ITherapy,ILipolysis, IDermaplaning,IMesotherapie} from '../features/setup/models/MainInformation'; 
import { Grid, Container, Typography,Stack,Box, Button } from '@mui/material';
import useMainInformation from 'features/setup/services/MainInformation';
import { useQuery } from 'react-query';
import { isFalsy } from 'utility-types';
import { globalConfig } from 'config';
import { AppointmentFormDialog } from 'components/AppointmentFormDialog';
import { typographyBigGroupBoxStyling, typographyGroupBoxStyling, typographySmallHandWriting } from 'themes/commonStyles';
import { useTranslation } from 'react-i18next';

const PrestationMedicalAestethic = () => {
    const { t, i18n } = useTranslation();
    const {  getReservations, getPRP,getTherapy,getLipolysis, getMesotherapie,getDermaplaning } = useMainInformation();
    const {data: prp} = useQuery<IPRP[]>(['PRP'], () => getPRP());
    const {data: therapies} = useQuery<ITherapy[]>(['Therapy'], () => getTherapy());
    const {data: lipolys} = useQuery<ILipolysis[]>(['Lipolysis'], () => getLipolysis());
    const {data: Mesotherapies} = useQuery<IMesotherapie[]>(['Mesotherapie'], () => getMesotherapie());
    const {data: Dermaplanings} = useQuery<IDermaplaning[]>(['Dermaplaning'], () => getDermaplaning());

    const {data: reservations} = useQuery<IReservation[]>( ['Reservation'], () => getReservations());
    const [openAppointmentForm, setOpenAppointmentForm] = useState<boolean>(false);

    const [reservation, setReservation] = useState<IReservation>(defaultReservation);
    useEffect(() => {
      console.log(reservations);
      if(!isFalsy(reservations) && reservations?.length>0)
        setReservation(reservations[0]);
  }, [reservations]);

  return (
    <Container maxWidth='xl'sx={{marginTop:'30px'}}>
      < Container  maxWidth='xl' sx={{bgcolor:'#DBA82F', color:'white'}}>

   <Grid container >
          <Grid item xs={12} md={8}>
          <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                <Typography variant="h1" sx={{fontSize:'40px',marginTop:'50px',textAlign: { xs: 'center', md: 'left' },typography: { xs: 'h4', md: 'h4' }}} {...typographySmallHandWriting}> 
                  {t('PRP : Plasma Rich Plaquet')}
                </Typography>                
              </Box>
          </Grid>
          <Typography sx={{marginTop:'150px'}}></Typography>
          <Grid item xs={6} md={4}></Grid>
        </Grid>
    <Grid container item>
      {( prp || []).map( (plasma, idx) => (
        
        <Grid xs={12}  md={3} key={plasma.id} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
          <Typography variant='h6' sx={{fontFamily:'Poppins', fontWeight:'bold'}}>{plasma.titre}</Typography>
          <Typography variant='h6' sx={{fontFamily:'Poppins'}}>{plasma.duree}</Typography>
          <Typography variant='h6' sx={{fontFamily:'Poppins'}}>{plasma.prix}</Typography>
          <Typography sx={{marginTop:'13px'}}></Typography>
        </Grid>
        
      ))}
    </Grid> 
         
  <Stack>
    <Grid container>
    
    <Grid item xs={12} md={4}></Grid>
    <Grid item xs={12} md={4} sx={{ mt: 0.5, width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Button variant="contained" onClick={() => {setOpenAppointmentForm(true);}}
                    sx={{
                        background:'#D85352', color:'#fff', 
                        backgroundImage: 'linear-gradient(to right, #371F07, #DBA82F)',
                        marginTop:'15px',fontFamily: 'Poppins !important', 
                        width:'70%', height:'55px', borderRadius:'30px'}} >
                  Faites votre réservation
                </Button>
              </Grid>
              <Grid item xs={12} md={4}></Grid>
              </Grid>
              { openAppointmentForm && <AppointmentFormDialog open={openAppointmentForm} setOpen={setOpenAppointmentForm}  /> } 
          
  </Stack>
  <Typography sx={{marginTop:'50px'}}></Typography>
  </Container>
  <Typography sx={{marginTop:'50px'}}></Typography>

  <Container  maxWidth='xl' sx={{bgcolor:'#371F07', color:'white'}} >
    <Grid container >
    <Grid item xs={12} md={8}>
  <Box
    sx={{
      mt: 1,
      width: '100%',
      display: 'flex',
      justifyContent: { xs: 'center', md: 'flex-start' }, // Centrer sur petit écran
    }}
  >
    <Typography
      variant="h1"
      sx={{
        fontSize: { xs: '45px', md: '40px' }, // Ajustement de la taille du texte pour petits écrans
        marginTop: { xs: '20px', md: '50px' }, // Ajustement de la marge pour petits écrans
        textAlign: { xs: 'center', md: 'left' }, // Centrage sur petit écran
        width: '100%', // Assure que la largeur est de 100%
      }}
      {...typographySmallHandWriting}
    >
      {t('IV Therapy Drip')}
    </Typography>
  </Box>
</Grid>
          <Typography sx={{marginTop:'150px'}}></Typography>
          <Grid item xs={12} md={4}></Grid>
        </Grid>
    <Grid container item>
      {( therapies || []).map( (therapie, idx) => (
        
        <Grid xs={12}  md={3} key={therapie.id} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
          <Typography variant='h6' sx={{fontFamily:'Poppins', fontWeight:'bold'}}>{therapie.titre}</Typography>
          <Typography variant='h6' sx={{fontFamily:'Poppins'}}>{therapie.duree}</Typography>
          <Typography variant='h6' sx={{fontFamily:'Poppins'}}>{therapie.prix}</Typography>
          <Typography sx={{marginTop:'13px'}}></Typography>
        </Grid>
        
      ))}
    </Grid> 
         
  <Stack>
    <Grid container>
    
    <Grid item xs={12} md={4}></Grid>
    <Grid item xs={12} md={4} sx={{ mt: 0.5, width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Button variant="contained" onClick={() => {setOpenAppointmentForm(true);}}
                    sx={{
                        background:'#D85352', color:'#fff', 
                        backgroundImage: 'linear-gradient(to right, #371F07, #DBA82F)',
                        marginTop:'15px',fontFamily: 'Poppins !important', 
                        width:'70%', height:'55px', borderRadius:'30px'}} >
                  Faites votre réservation
                </Button>
              </Grid>
              <Grid item xs={12} md={4}></Grid>
              </Grid>
              { openAppointmentForm && <AppointmentFormDialog open={openAppointmentForm} setOpen={setOpenAppointmentForm}  /> } 
          
  </Stack>
  <Typography sx={{marginTop:'50px'}}></Typography>
  </Container>

  <Typography sx={{marginTop:'50px'}}></Typography>

<Container  maxWidth='xl' sx={{bgcolor:'#DBA82F', color:'white'}}>
  <Grid container >
  <Grid item xs={12} md={8}>
  <Box
    sx={{
      mt: 1,
      width: '100%',
      display: 'flex',
      justifyContent: { xs: 'center', md: 'flex-start' }, // Centrer sur petit écran
    }}
  >
    <Typography
      variant="h1"
      sx={{
        fontSize: { xs: '45px', md: '40px' }, // Ajustement de la taille du texte pour petits écrans
        marginTop: { xs: '20px', md: '50px' }, // Ajustement de la marge pour petits écrans
        textAlign: { xs: 'center', md: 'left' }, // Centrage sur petit écran
        width: '100%', // Assure que la largeur est de 100%
      }}
      {...typographySmallHandWriting}
    >
      {t('Mésothérapie')}
    </Typography>
  </Box>
</Grid>

        <Typography sx={{marginTop:'150px'}}></Typography>
        <Grid item xs={6} md={4}></Grid>
      </Grid>
  <Grid container item>
    {( Mesotherapies || []).map( (Mesotherapie, idx) => (
      
      <Grid xs={12}  md={3} key={Mesotherapie.id} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
        <Typography variant='h6' sx={{fontFamily:'Poppins', fontWeight:'bold'}}>{Mesotherapie.titre}</Typography>
        <Typography variant='h6' sx={{fontFamily:'Poppins'}}>{Mesotherapie.duree}</Typography>
        <Typography variant='h6' sx={{fontFamily:'Poppins'}}>{Mesotherapie.prix}</Typography>
        <Typography sx={{marginTop:'13px'}}></Typography>
      </Grid>
      
    ))}
  </Grid> 
       
<Stack>
  <Grid container>
  
  <Grid item xs={12} md={4}></Grid>
  <Grid item xs={12} md={4} sx={{ mt: 0.5, width: '100%', display: 'flex', justifyContent: 'center'}}>
              <Button variant="contained" onClick={() => {setOpenAppointmentForm(true);}}
                  sx={{
                      background:'#D85352', color:'#fff', 
                      backgroundImage: 'linear-gradient(to right, #371F07, #DBA82F)',
                      marginTop:'15px',fontFamily: 'Poppins !important', 
                      width:'70%', height:'55px', borderRadius:'30px'}} >
                Faites votre réservation
              </Button>
            </Grid>
            <Grid item xs={12} md={4}></Grid>
            </Grid>
            { openAppointmentForm && <AppointmentFormDialog open={openAppointmentForm} setOpen={setOpenAppointmentForm}  /> } 
        
</Stack>
<Typography sx={{marginTop:'50px'}}></Typography>
</Container>



<Typography sx={{marginTop:'50px'}}></Typography>

  <Container  maxWidth='xl' sx={{bgcolor:'#371F07', color:'white'}}>
    <Grid container >
    <Grid item xs={12} md={8}>
  <Box
    sx={{
      mt: 1,
      width: '100%',
      display: 'flex',
      justifyContent: { xs: 'center', md: 'flex-start' }, // Centrer sur petit écran
    }}
  >
    <Typography
      variant="h1"
      sx={{
        fontSize: { xs: '45px', md: '40px' }, // Ajustement de la taille du texte pour petits écrans
        marginTop: { xs: '20px', md: '50px' }, // Ajustement de la marge pour petits écrans
        textAlign: { xs: 'center', md: 'left' }, // Centrage sur petit écran
        width: '100%', // Assure que la largeur est de 100%
      }}
      {...typographySmallHandWriting}
    >
      {t('Dermaplaning')}
    </Typography>
  </Box>
</Grid>
          <Typography sx={{marginTop:'150px'}}></Typography>
          <Grid item xs={6} md={4}></Grid>
        </Grid>
    <Grid container item>
      {( Dermaplanings || []).map( (Dermaplaning, idx) => (
        
        <Grid xs={12}  md={3} key={Dermaplaning.id} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
          <Typography variant='h6' sx={{fontFamily:'Poppins', fontWeight:'bold'}}>{Dermaplaning.titre}</Typography>
          <Typography variant='h6' sx={{fontFamily:'Poppins'}}>{Dermaplaning.duree}</Typography>
          <Typography variant='h6' sx={{fontFamily:'Poppins'}}>{Dermaplaning.prix}</Typography>
          <Typography sx={{marginTop:'13px'}}></Typography>
        </Grid>
        
      ))}
    </Grid> 
         
  <Stack>
    <Grid container>
    
    <Grid item xs={12} md={4}></Grid>
    <Grid item xs={12} md={4} sx={{ mt: 0.5, width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Button variant="contained" onClick={() => {setOpenAppointmentForm(true);}}
                    sx={{
                        background:'#D85352', color:'#fff', 
                        backgroundImage: 'linear-gradient(to right, #371F07, #DBA82F)',
                        marginTop:'15px',fontFamily: 'Poppins !important', 
                        width:'70%', height:'55px', borderRadius:'30px'}} >
                  Faites votre réservation
                </Button>
              </Grid>
              <Grid item xs={12} md={4}></Grid>
              </Grid>
              { openAppointmentForm && <AppointmentFormDialog open={openAppointmentForm} setOpen={setOpenAppointmentForm}  /> } 
          
  </Stack>
  <Typography sx={{marginTop:'50px'}}></Typography>
  </Container>



  <Typography sx={{marginTop:'50px'}}></Typography>


  <Container  maxWidth='xl' sx={{bgcolor:'#DBA82F', color:'white'}}>
    <Grid container >
          <Grid item xs={12} md={8}>
          <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                <Typography variant="h1" sx={{fontSize:'40px',marginTop:'50px',textAlign: { xs: 'center', md: 'left' },typography: { xs: 'h4', md: 'h4' }}} {...typographySmallHandWriting}> 
                  {t('Injection Lipolysis Fat Disolving')}
                </Typography>                
              </Box>
          </Grid>
          <Typography sx={{marginTop:'150px'}}></Typography>
          <Grid item xs={6} md={4}></Grid>
        </Grid>
    <Grid container item>
      {( lipolys || []).map( (lipoly, idx) => (
        
        <Grid xs={12}  md={3} key={lipoly.id} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
          <Typography variant='h6' sx={{fontFamily:'Poppins', fontWeight:'bold'}}>{lipoly.titre}</Typography>
          <Typography variant='h6' sx={{fontFamily:'Poppins'}}>{lipoly.duree}</Typography>
          <Typography variant='h6' sx={{fontFamily:'Poppins'}}>{lipoly.prix}</Typography>
          <Typography sx={{marginTop:'13px'}}></Typography>
        </Grid>
        
      ))}
    </Grid> 
      
       
  <Stack>
    <Grid container>
    
    <Grid item xs={12} md={4}></Grid>
    <Grid item xs={12} md={4} sx={{ mt: 0.5, width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Button variant="contained" onClick={() => {setOpenAppointmentForm(true);}}
                    sx={{
                        background:'#D85352', color:'#fff', 
                        backgroundImage: 'linear-gradient(to right, #371F07, #DBA82F)',
                        marginTop:'15px',fontFamily: 'Poppins !important', 
                        width:'70%', height:'55px', borderRadius:'30px'}} >
                  Faites votre réservation
                </Button>
              </Grid>
              <Grid item xs={12} md={4}></Grid>
              </Grid>
              { openAppointmentForm && <AppointmentFormDialog open={openAppointmentForm} setOpen={setOpenAppointmentForm}  /> } 
          
  </Stack>
  <Typography sx={{marginTop:'50px'}}></Typography>
  </Container>
 <Typography sx={{marginTop:'30px'}}></Typography>

    <Grid container>
        <Grid item xs={12} md={6}>            
            <Stack flexDirection='column' textAlign="center" >
              <Box sx={{ mt: 1, p: 2, width: '100%', display: 'flex', justifyContent: 'center'}}> 
              <img 
               src={`${globalConfig.get().apiUrl}/download/${reservation.image_Url}`} 
               alt="..." 
               style={{ height: '450px', objectFit: 'cover', borderRadius: '2%', width: '100%' }}
                />

              </Box>              
            </Stack>
          </Grid>  
          <Grid item  md={6}> </Grid> 
    </Grid>   
    <Typography sx={{marginTop:'30px'}}></Typography>
    </Container>
  );
};

export default PrestationMedicalAestethic;
