import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import useMainInformation from 'features/setup/services/MainInformation';
import { isFalsy } from 'utility-types';
import { IDefMainPied, defaultMainPied } from 'features/setup/models/MainInformation';
import { globalConfig } from 'config';
import { Box, Container, Grid, Typography } from '@mui/material';
import PrestationMainPied from 'components/PrestationMainPied';

const MainEtPied = () => {
  const { getDefMainPied } = useMainInformation();
  const { data: MainsPieds } = useQuery<IDefMainPied[]>(['MainPied'], () => getDefMainPied());

  const [MainPied, setSoinsVisage] = useState<IDefMainPied>(defaultMainPied);

  useEffect(() => {
    if (!isFalsy(MainsPieds) && MainsPieds?.length > 0) setSoinsVisage(MainsPieds[0]);
  }, [MainsPieds]);

  return (
    <Box>
      <Container maxWidth="xl" sx={{ paddingY: 4 }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={`${globalConfig.get().apiUrl}/download/${MainPied.image_Url}`}
              alt="soins de corps"
              sx={{
                width: '100%',
                height: { xs: 'auto', md: 450 },
                maxHeight: '450px',
                objectFit: 'cover',
                borderRadius: 2,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography
              variant="h3"  // Keep a single variant
              sx={{
                marginTop: { xs: 2, md: '110px' },
                fontFamily: 'Poppins',
                textAlign: { xs: 'center', md: 'left' },
                typography: { xs: 'h4', md: 'h3' },  // Adjust typography size based on breakpoints
              }}
            >
              {MainPied.titrePrincipal}
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontFamily: 'Poppins', textAlign: { xs: 'justify', md: 'left' }, marginBottom: 10  }}
            >
              {MainPied.titreSecondaire}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <PrestationMainPied />
    </Box>
  );
};

export default MainEtPied;
