import React from 'react'
import Reservation from './setup/Reservation'

const Reservations = () => {
  return (
    <div>
      <Reservation/>
    </div>
  )
}

export default Reservations
