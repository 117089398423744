export interface IVisitorMessage {
    
    id: number,

    token: string,
    instanceId: string,

    messageDate: Date,

    firstName: string,
    lastName: string,
    portable: string,
    
    email: string,
    
    messageObject: string,

    messageBody: string
}

export const defaultVisitorMessage : IVisitorMessage = {
    
    id: 0,

    token: '',
    instanceId: '',

    messageDate: new Date(),

    firstName: '',
    lastName: '',
    portable: '',
    
    email: '',
    
    messageObject: '',

    messageBody: ''
}


export interface IVisitorMessageSearch {
    startDate: Date,
    endDate: Date,

    name: string,
    
    messageObject: string,
    messageBody: string
  }