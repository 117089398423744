
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IVisitorMessage, IVisitorMessageSearch } from "../models/VisitorMessage";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { globalConfig } from 'config';

import { currentUserSessionAtom } from 'library/store';
import { addMonths } from 'date-fns';
//import { IPolicyRisk, IPolicyRiskCoverage } from 'features/visitorMessageion/models/Policy';


const _ = () => {

    const axios = useAxios(); 

    const createVisitorMessage = async (visitorMessage: IVisitorMessage)  =>       
        await (await axios.post(`${globalConfig.get().apiUrl}/api/production/visitorMessage/v1/${globalConfig.get().applicationApiToken}/create-public`, visitorMessage)).data;       
        
    const updateVisitorMessage = async (visitorMessage: IVisitorMessage)  =>       
        await (await axios.post(`${globalConfig.get().apiUrl}/api/production/visitorMessage/v1/${globalConfig.get().applicationApiToken}/update-public`, visitorMessage)).data; 
    
    const getVisitorMessage = async (id  : number )  => {
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/visitorMessage/v1/${globalConfig.get().applicationApiToken}/${id}`));
      return await data;
    }
   

    const getVisitorMessages = async (criteria: IVisitorMessageSearch, pagination?: IPagination) : Promise<IVisitorMessage[]> => {

      const { startDate, endDate, name, messageObject, messageBody } = criteria;

      const sStartDate = encodeURIComponent(startDate.toISOString());
      const sEndDate = encodeURIComponent(endDate.toISOString());

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/visitorMessage/v1/get-visitorMessages?&name=${name}&messageObject=${messageObject}&messageBody=${messageBody}&sStartDate=${sStartDate}&sEndDate=${sEndDate}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }
     
    return {    
      createVisitorMessage,
      updateVisitorMessage,
      getVisitorMessage,
      getVisitorMessages,

    } 
}

export default _;

export interface IFilterVisitorMessageOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IVisitorMessage[], React.Dispatch<React.SetStateAction<IVisitorMessage[]>>],
}

const defaultFilterVisitorMessageOption: IFilterVisitorMessageOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterVisitorMessage = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IVisitorMessage) => void,
                                            filterOption?: IFilterVisitorMessageOption  ) => {

  const { getVisitorMessages } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterVisitorMessageOption;



  const [headVisitorMessageCells, setHeadVisitorMessageCells]  = useState<HeadCell<IVisitorMessage>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'firstName', label : t('Name'),  display: true, type: 'string', },
    {id:'lastName', label : t('Description'),  display: true, type: 'string', },
    
    
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
    
      {name: 'startDate', text: t('Start date'), dataType: 'date' ,  value: '', dateValue: addMonths(new Date(), -1)},
      {name: 'endDate', text: t('End date'), dataType: 'date'  , value: '', dateValue: new Date()},

      {name: 'name', text: t('Name'), value: ''},
      {name: 'messageObject', text: t('Subject'), value: ''},
      {name: 'messageBody', text: t('Message'), value: ''},
    ]);    

  const [filteredVisitorMessages, ] = useState<IVisitorMessage[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IVisitorMessage[]> => {
   
    const startDate = filterElements.find( elt => elt.name === 'startDate')?.dateValue || new Date();
    const endDate = filterElements.find( elt => elt.name === 'endDate')?.dateValue || new Date();


    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const messageObject = filterElements.find( elt => elt.name === 'messageObject')?.value || '';
    const messageBody = filterElements.find( elt => elt.name === 'messageBody')?.value || '';
    
    const arr = await getVisitorMessages({startDate, endDate, name, messageObject, messageBody}, pagination );
    
    return arr;
  }

  const objKey: keyof IVisitorMessage = 'id';

  return {
    title: t('VisitorMessage'), headCells: headVisitorMessageCells, objKey,
    filterElements, rows: filteredVisitorMessages, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}

