import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import useMainInformation from './services/MainInformation';
import { Typography, Box, Container, Grid } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { isFalsy } from 'utility-types';
import { defaultDefFormation, IAvis, IDefFormation } from './models/MainInformation';
import { typographyBigGroupBoxStyling, typographySmallHandWriting } from 'themes/commonStyles';

const AvisApprenants = () => {
  const { getAvisApprenants, getDefFormations } = useMainInformation();
  const { data: AvisInformation } = useQuery<IAvis[]>(['Avis'], () => getAvisApprenants());
  const { data: FormationInformations } = useQuery<IDefFormation[]>(['DefFormation'], () => getDefFormations());
  const [formations, setDefFormations] = useState<IDefFormation>(defaultDefFormation);

  useEffect(() => {
    if (!isFalsy(FormationInformations) && FormationInformations.length > 0)
      setDefFormations(FormationInformations[0]);
  }, [FormationInformations]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };

  return (
    <Box py={8} bgcolor='#784828'>
      <Container maxWidth='xl'>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Box textAlign="justify">
              <Typography variant="h4" gutterBottom sx={{ fontSize: '45px', color: 'white' }} {...typographySmallHandWriting}>
                {formations.titre2}
              </Typography>
              <Typography variant="h6" color="white" fontFamily="Poppins">
                {formations.sousTitre2}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}></Grid>
        </Grid>
        <Typography sx={{ marginTop: '5px' }}></Typography>
        <Slider {...settings}>
          {AvisInformation && AvisInformation.map(avis => (
            <div key={avis.id}>
              <Box p={3} bgcolor="white" borderRadius={4} boxShadow={3} textAlign="center">
                <Typography variant="h6" color="black" fontFamily="Poppins">
                  {avis.commentaires}
                </Typography>
                <Typography variant="h6" color="black" fontFamily="Poppins">
                  {avis.nom} {avis.date}
                </Typography>
              </Box>
            </div>
          ))}
        </Slider>
      </Container>
      <Typography sx={{ marginTop: '6px' }}></Typography>
    </Box>
  );
};

export default AvisApprenants;
