import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import useMainInformation from 'features/setup/services/MainInformation';
import { isFalsy } from 'utility-types';
import { IDefSoinCorps, defaultSoinsCorps } from 'features/setup/models/MainInformation';
import { globalConfig } from 'config';
import { Box, Container, Grid, Typography } from '@mui/material';
import PrestationSoinCorps from 'components/PrestationSoinCorps';

const SoinsDeCorps = () => {
  const { getDefSoinsCorps } = useMainInformation();
  const { data: SoinsCorps } = useQuery<IDefSoinCorps[]>(['SoinCorps'], () => getDefSoinsCorps());

  const [SoinCorps, setSoinsCorps] = useState<IDefSoinCorps>(defaultSoinsCorps);

  useEffect(() => {
    if (!isFalsy(SoinsCorps) && SoinsCorps?.length > 0) setSoinsCorps(SoinsCorps[0]);
  }, [SoinsCorps]);

  return (
    <Box>
      <Container maxWidth="xl" sx={{ paddingY: 4 }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={`${globalConfig.get().apiUrl}/download/${SoinCorps.image_Url}`}
              alt="soins de corps"
              sx={{
                width: '100%',
                height: { xs: 'auto', md: 450 },  // Fixed height on medium screens and up
                maxHeight: '450px',
                objectFit: 'cover',
                borderRadius: 2,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography
              variant="h3"  // Keep a single variant
              sx={{
                marginTop: { xs: 2, md: '110px' },
                fontFamily: 'Poppins',
                textAlign: { xs: 'center', md: 'left' },
                typography: { xs: 'h5', md: 'h3' },  // Adjust typography size based on breakpoints
              }}
            >
              {SoinCorps.titrePrincipal}
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontFamily: 'Poppins', textAlign: { xs: 'justify', md: 'left' }, marginBottom: 10 }}
            >
              {SoinCorps.titreSecondaire}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <PrestationSoinCorps />
    </Box>
  );
};

export default SoinsDeCorps;
