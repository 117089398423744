import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import useMainInformation from 'features/setup/services/MainInformation';
import { isFalsy } from 'utility-types';
import { IDefMedicalAestetic, defaultMedicalAestetic } from 'features/setup/models/MainInformation';
import { globalConfig } from 'config';
import { Box, Container, Grid, Typography } from '@mui/material';
import PrestationMedicalAestethic from 'components/PrestationMedicalAestethic';

const MedicalAesthetic = () => {
  const { getDefMedicalAesthetic } = useMainInformation();
  const { data: MedicalAesthetics } = useQuery<IDefMedicalAestetic[]>(['DefMedicalAestetic'], () => getDefMedicalAesthetic());

  const [Medical, setMedical] = useState<IDefMedicalAestetic>(defaultMedicalAestetic);

  useEffect(() => {
    if (!isFalsy(MedicalAesthetics) && MedicalAesthetics?.length > 0) setMedical(MedicalAesthetics[0]);
  }, [MedicalAesthetics]);

  return (
    <Container maxWidth="xl" sx={{ paddingY: 4 }}>
      <Box>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={`${globalConfig.get().apiUrl}/download/${Medical.image_Url}`}
              alt="soins de visage"
              sx={{
                width: '100%',
                height: { xs: 'auto', md: 420 },
                maxHeight: '450px',
                objectFit: 'cover',
                borderRadius: 3,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography
              variant="h3"  // Keep a single variant
              sx={{
                marginTop: { xs: 2, md: '110px' },
                fontFamily: 'Poppins',
                textAlign: { xs: 'center', md: 'left' },
                typography: { xs: 'h5', md: 'h3' },  // Adjust typography size based on breakpoints
              }}
            >
              {Medical.titrePrincipal}
            </Typography>
            <Typography variant="h6" sx={{ fontFamily: 'Poppins', textAlign: { xs: 'justify', md: 'left' }, marginBottom: 10  }}>
              {Medical.titreSecondaire}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <PrestationMedicalAestethic />
    </Container>
  );
};

export default MedicalAesthetic;
