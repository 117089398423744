import React, { MouseEvent, useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useMainInformation from "features/setup/services/MainInformation";
import { isFalsy } from 'utility-types';
import { Typography, Box, Link, Divider, Container, Grid, Icon,TextField, Button} from '@mui/material';
import { IMainInformation, defaultMainInformation } from 'features/setup/models/MainInformation';
import { typographyBigGroupBoxStyling, typographyGroupBoxStyling, typographySmallHandWriting } from 'themes/commonStyles';
import { useTranslation } from 'react-i18next';
import { Phone, Email, AccessTime } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { IVisitorMessage, defaultVisitorMessage } from './models/VisitorMessage';
import useVisitorMessageService, { useBasicFilterVisitorMessage } from './services/VisitorMessage';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom, currentUserSessionAtom } from 'library/store';

import { useForm } from 'react-hook-form';
import { IResult } from 'library/interface';
import { enqueueSnackbar } from 'notistack';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';

import { globalConfig } from 'config';

const CustomTextField = styled(TextField)({
  '& input, & textarea': {
    color: '#fff', // Couleur du texte par défaut
    fontFamily: 'Poppins',
  },
  '& input::placeholder, & textarea::placeholder': {
    color: '#fff', // Couleur du placeholder
    fontFamily: 'Poppins',
  },
  '& input:focus, & textarea:focus': {
    color: '#fff', // Couleur du texte lorsqu'en focus
  }
});





const Contact: React.FC<IVisitorMessage> = (props: IVisitorMessage = defaultVisitorMessage) =>  {
  const { t, i18n } = useTranslation();


  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {  getMainInformations } = useMainInformation();
  const { data: mainInformationData } = useQuery<IMainInformation[]>(['MainInformation'], () => getMainInformations());
  const [mainInformation, setMainInformations] = useState<IMainInformation>(defaultMainInformation);


  const { createVisitorMessage, updateVisitorMessage } = useVisitorMessageService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const methods = useForm<IVisitorMessage>({defaultValues: {...defaultVisitorMessage }});

  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;


  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IVisitorMessage>,Error,IVisitorMessage>(
      _id>0?updateVisitorMessage:createVisitorMessage, {   
        onSuccess: (data: IResult<IVisitorMessage>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 3000 }); 
                   
          setIsSaveLoading(false);
          _setId(0);

          reset({...defaultVisitorMessage});
          //setCurrentEntityIdForAction(data.data.id);
          //setCurrentFormNameAtom(`${t('Appointment')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['Appointment',data.data.id]);
         
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          
          setIsSaveLoading(false);
        }
      });
      
  useEffect(() => {
    if (!isFalsy(mainInformationData) && mainInformationData.length > 0)
      setMainInformations(mainInformationData[0]);
  }, [mainInformationData]);


  const saveVisitorMessage = async (event: MouseEvent<HTMLButtonElement>) => {      
    // if(!checkEntitySaveAuthorization('Appointment', _id)) {
    //   setIsSaveLoading(false);
    //      return;
    // }          

    const data = getValues(); 

    // if( isFalsy(data.lineOfBusinessCode) || data.lineOfBusinessCode.trim() === '') {
    //   enqueueSnackbar( t('Line of business is not specified'), { variant: 'warning',
    //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
    //   setIsSaveLoading(false);
    //   return;
    // }

            
    if(data.firstName.trim() === '' || data.lastName.trim() === '') {
        enqueueSnackbar( t('Name is required'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        setIsSaveLoading(false);
        return;
      }

      if(data.portable.trim() === '') {
        enqueueSnackbar( t('Portable is required'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        setIsSaveLoading(false);
        return;
      }


    mutate({...data,
        token: globalConfig.get().shineToken,
        instanceId: globalConfig.get().shineInstanceId,

        });
  }

 

  return (
    <Box>
      <Container maxWidth='xl' sx={{marginTop:'70px' }}>
        <Grid container>
          <Grid item xs={12} md={8}>
          <Box sx={{ mt: 1, width: '100%', display: 'flex'}}>
                <Typography variant="h1" sx={{fontSize:'40px'}} {...typographySmallHandWriting}> 
                  {t('CONTACT')}
                </Typography>                
              </Box>
          <Typography variant='h6' sx={{fontFamily:'Poppins'}}>{t('Nous sommes là pour vous aider à planifier votre prochaine expérience de bien-être. Si vous avez des questions ou souhaitez prendre rendez-vous, n’hésitez pas à nous contacter.')}</Typography>
          </Grid>
          <Grid item xs={12} md={4}></Grid>
        </Grid>
        
      </Container>

      

<Container maxWidth='xl' sx={{ marginTop: '30px', color: 'white', backgroundColor: '#371F07' }}>

  <Grid container >
 
    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Typography sx={{marginTop:'20px' }}></Typography>
      <Icon component={Phone} sx={{ fontSize: 45, marginBottom: '10px' }} />
      <Typography variant="h6" sx={{ fontFamily:'Poppins', fontWeight:'bold'}}>{t('CONTACT TELEPHONIQUE')}</Typography>
      <Typography variant='h6' sx={{ fontFamily:'Poppins'}}>{mainInformation.portable1}</Typography>
      <Typography sx={{marginTop:'20px' }}></Typography>
    </Grid>
    

    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
    <Typography sx={{marginTop:'20px' }}></Typography>
      <Icon component={Email} sx={{ fontSize: 45, marginBottom: '10px' }} />
      <Typography variant="h6" sx={{ fontFamily:'Poppins', fontWeight:'bold'}}>{t('ADRESSE EMAIL')}</Typography>
      <Typography variant='h6' sx={{ fontFamily:'Poppins'}}>{mainInformation.email1}</Typography>
      <Typography sx={{marginTop:'20px' }}></Typography>
    </Grid>

    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Typography sx={{marginTop:'20px' }}></Typography>
      <Icon component={AccessTime} sx={{ fontSize: 45, marginBottom: '10px' }} />
      <Typography variant="h6" sx={{ fontFamily:'Poppins', fontWeight:'bold'}}>{t('HORAIRE')}</Typography>
      <Typography variant='h6' sx={{ fontFamily:'Poppins'}}>{mainInformation.horaire1}</Typography>
      <Typography sx={{marginTop:'20px' }}></Typography>
    </Grid>
   
  </Grid>

</Container>;



<Container maxWidth='xl' sx={{ marginTop: '30px', backgroundColor: 'white' }}>
  <Grid container sx={{ marginTop: '10px' }}>
    <Grid item xs={12} md={3}></Grid>
    <Grid item xs={12} md={6}>
      <Typography variant="h3" sx={{ textAlign:'center', fontFamily:'Poppins' }}> {t('Veuillez remplir le formulaire de contact ci-dessous')}</Typography> 
      <Typography variant="h6" sx={{ textAlign:'center', fontFamily:'Poppins' }}>{t('Nous sommes à votre disposition pour vous aider à atteindre votre état de bien-être ultime')}</Typography> 
    </Grid>
    <Grid item xs={12} md={3}></Grid>
  </Grid>
  <Grid container spacing={2} sx={{ p: 3 }}>
    <Grid item xs={12} md={6}>
      {/* <Typography variant="body1" sx={{ color: '#371F07', fontFamily:'Poppins', fontWeight:'bold' }}>{t('VOTRE NOM ?  *')}</Typography>       */}
      <TextField required fullWidth placeholder='Ex : BIOLE' label={t('VOTRE NOM')} {...register('lastName')}
        inputProps={ {autoComplete: 'new-password', style: {textTransform: 'none'} }}  />
    </Grid>
    <Grid item xs={12} md={6}>      
      <TextField required fullWidth placeholder='Ex : Jeanne' label={t('VOTRE PRÉNOM')} {...register('firstName')}
        inputProps={ {autoComplete: 'new-password', style: {textTransform: 'none'} }}  />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField required fullWidth placeholder='biole.jeanne@mail.com' label={t('VOTRE ADRESSE EMAIL')} {...register('email')}
        inputProps={ {autoComplete: 'new-password', style: {textTransform: 'none'} }}  />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField required fullWidth placeholder='Ex : 6 90 57 69 32' label={t('VOTRE NUMERO DE TÉLÉPHONE')} {...register('portable')}
        inputProps={ {autoComplete: 'new-password', style: {textTransform: 'none'} }}  />      
    </Grid>
    <Grid item xs={12}>
      <TextField required fullWidth placeholder='Ex : Demande de devis' label={t('OBJET DU MESSAGE')} {...register('messageObject')}
        inputProps={ {autoComplete: 'new-password', style: {textTransform: 'none'} }}  />      
    </Grid>
    
    
    <Grid item xs={12}>
      <TextField required fullWidth placeholder='Ex : Demande de devis' label={t('LAISSEZ NOUS VOTRE MESSAGE')} {...register('messageBody')}
        inputProps={ {autoComplete: 'new-password', style: {textTransform: 'none'} }} multiline rows={4}  />
      
    </Grid>
    <Grid item xs={12} md={4}></Grid>
    <Grid item xs={12} md={4} sx={{ marginTop: '5px' }}>
      <Button fullWidth variant="contained" onClick={saveVisitorMessage}
        sx={{ color: 'white', fontFamily: 'Poppins', fontWeight: 'bold', bgcolor: '#784828', 
              height:'45px','&:hover': {bgcolor: '#371F07' } }}>Envoyer
      </Button>
    </Grid>
    <Grid item xs={12} md={4}></Grid>
  </Grid>
</Container>


      
    </Box>
  );
};

export default Contact;
