import React from 'react';
import Slider from 'react-slick';
import { Box, Typography } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export interface CarouselImagesProps {
  images: { src: string; mainTitle: string; secondaryTitle: string }[];
}

const CarouselImages = (props: CarouselImagesProps) => {
  const { images } = props;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  };

  return (
    <Slider {...settings}>
      {images.map((image, idx) => (
        <div key={`slide-${idx}`}>
          <Box
            sx={{
              position: 'relative',
              height: '570px',
              overflow: 'hidden',
            }}
          >
            <img
              src={image.src}
              alt={`Slide ${idx}`}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: { xs: '50%', md: '80%' },
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                background: 'rgba(0, 0, 0, 0.5)',
                padding: '20px',
                borderRadius: '5px',
                display: { xs: 'flex', md: 'block' },
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: { xs: '90%', md: 'auto' }, // Increase width on small screens
              }}
            >
              <Typography
                variant="h4"
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontWeight: { xs: '500', md: 'bolder' },
                  fontSize: { xs: '1.3rem', md: '2.5rem' } // Adjust font size based on screen size
                }}
                color="white"
                display={{ xs: 'block', md: 'none' }}
              >
                {image.mainTitle}
              </Typography>
              <Typography
                variant="h4"
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontWeight: 'bolder',
                  fontSize: { xs: '1.5rem', md: '2.5rem' } // Adjust font size based on screen size
                }}
                color="white"
                display={{ xs: 'none', md: 'block' }}
              >
                {image.mainTitle}
              </Typography>
              <Typography
                variant="h6"
                sx={{ 
                  fontFamily: 'Poppins',
                  fontSize: { xs: '1rem', md: '1.5rem' } // Adjust font size based on screen size
                }}
                color="white"
                display={{ xs: 'none', md: 'block' }}
              >
                {image.secondaryTitle}
              </Typography>
            </Box>
          </Box>
        </div>
      ))}
    </Slider>
  );
};

export default CarouselImages;
